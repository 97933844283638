import { Link } from 'gatsby'

import HeadPagesBlock from '~/components/blocks/HeadPagesBlock'
import MainLayout from '~/components/layouts/MainLayout'

import SitemapList from './components/SitemapList'
import * as containerStyles from './Sitemap.module.scss'

const Sitemap = ({ data }) => {
  const excludedCompanyPages = [
    'scam warning',
    'subscription',
    'thank',
    'homepage',
    'download',
    'services',
    'sitemap',
    'search',
    'thanks',
    'blog',
    'case',
    '404',
  ]

  return (
    <MainLayout isStaticChat>
      <HeadPagesBlock title="Sitemap" />

      <div className={containerStyles.sitemapPage}>
        <h2>
          <Link to={`/services/`}>Services</Link>
        </h2>
        <SitemapList
          pagePath
          items={data.allStrapiService.edges}
          link="services"
          linkKey="page_path"
          linkName="meta_title"
        />
        <h2>
          <Link to={`/case-studies/`}>Portfolio</Link>
        </h2>
        <SitemapList
          items={data.allStrapiCase.edges}
          link="case-studies"
          linkKey="page_name"
          linkName="meta_title"
        />
        <h2>
          <Link to={`/blog/`}>Blog</Link>
        </h2>
        <h3>Categories</h3>
        <SitemapList
          items={data.allStrapiCategory.edges}
          link="blog/categories"
          linkKey="url"
          linkName="meta_title"
        />
        <h3>Tags</h3>
        <SitemapList
          items={data.allStrapiTag.edges}
          link="blog/tag"
          linkKey="url"
          linkName="meta_title"
        />
        <h3>Articles</h3>
        <SitemapList
          pagePath
          items={data.allStrapiArticle.edges}
          link="blog"
          linkKey="url"
          linkName="title"
        />
        <h3>Authors</h3>
        <SitemapList
          items={data.allStrapiAuthor.edges}
          link="blog/author"
          linkKey="slug"
          linkName="name"
        />
        <h2>Company pages</h2>
        <SitemapList
          items={data.allStrapiPage.edges.filter(
            (page) =>
              !excludedCompanyPages.some((keyword) =>
                page.node.page_name.toLowerCase().includes(keyword),
              ),
          )}
          linkKey="page_name"
          linkName="meta_title"
        />
      </div>
    </MainLayout>
  )
}

export default Sitemap
