import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'

import SEO from '~/components/shared/Seo'
import { fileToImageLikeData, getPageMeta } from '~/utils'
import Sitemap from '~/views/Sitemap'

const SitemapPage = ({ data }) => <Sitemap data={data} />

export default SitemapPage

export const pageQuery = graphql`
  query ($published: [Boolean]) {
    strapiPage(page_name: { eq: "Sitemap" }) {
      ...MetaTagInformationPages
    }

    allStrapiPage(sort: { updatedAt: DESC }) {
      edges {
        node {
          meta_title
          page_name
        }
      }
    }

    allStrapiService(sort: { createdAt: DESC }) {
      edges {
        node {
          meta_title
          page_path
        }
      }
    }

    allStrapiCase(sort: { createdAt: DESC }) {
      edges {
        node {
          page_name
          meta_title
        }
      }
    }

    allStrapiCategory(sort: { order: ASC }) {
      edges {
        node {
          url
          name
          meta_title
        }
      }
    }

    allStrapiTag {
      edges {
        node {
          meta_title
          url
        }
      }
    }

    allStrapiAuthor {
      edges {
        node {
          name
          slug
        }
      }
    }

    allStrapiArticle(
      sort: { modified_date: DESC }
      filter: { active: { eq: true }, published: { in: $published } }
    ) {
      edges {
        node {
          title
          url
        }
      }
    }
  }
`

export const Head = ({ data }) => {
  const { meta_title, meta_description, og_image } = getPageMeta(
    data.strapiPage,
  )

  return (
    <SEO
      title={meta_title}
      description={meta_description}
      urlImage={getSrc(fileToImageLikeData(og_image?.localFile)) as string}
      url="https://www.codica.com/sitemap/"
    />
  )
}
